<template>
  <div>
    <h1 class="text-h4">{{ $lang.routes.massChanges }}</h1>
    <v-divider class="my-2"></v-divider>
    <v-row v-if="err || success" dense no-gutters class="px-3">
      <v-col v-if="err" cols="12">
        <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
      </v-col>
      <v-col v-if="success" cols="12">
        <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12">
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step
              :complete="e1 > 1"
              step="1"
            >
              {{ $lang.header.resourcesProperties }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="e1 > 2"
              step="2"
            >
              {{ $lang.header.values }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3">
              {{ $lang.header.result }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card
                class="mb-6 pa-2"
                color="surface"
                min-height="366px"
              >
                <v-row no-gutters>
                  <v-col cols="6" class="col-class-left">
                    <p>{{ $lang.header.selectResourceTypesToBeEdited }}</p>
                    <template v-for="(type, index) in resourcesFormatted">
                      <v-checkbox
                        :key="index"
                        v-model="selectedResources"
                        class="pt-1"
                        hide-details
                        dense
                        multiple
                        :label="type.text"
                        :value="type.value"
                      />
                    </template>
                  </v-col>
                  <v-col cols="6" class="pl-2">
                    <p>{{ $lang.header.selectPropertyYouWantToEdit }}</p>
                    <v-select
                      v-model="permissionType"
                      class="pt-2"
                      :items="typesFormatted"
                      :label="$lang.labels.propertyAction"
                      outlined
                      dense
                      clearable
                      item-text="text"
                      item-value="value"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <v-row no-gutters justify="end">
                <v-btn
                  color="primary"
                  :disabled="!selectedResources.length || !permissionType"
                  @click="e1 = 2"
                >
                  {{ $lang.actions.next }}
                </v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card
                class="mb-6 pa-2"
                color="surface"
                min-height="366px"
              >
                <v-row no-gutters>
                  <v-col cols="6" class="col-class-left pr-2">
                    <h3>{{ $lang.header.resources }}</h3>
                    <template v-for="(item, i) in selectedResources">
                      <values-component
                        :key="i"
                        :type="item"
                        :all-resources="resourcesTypes"
                        @setValues="setResources(item, $event)"
                      ></values-component>
                    </template>
                  </v-col>
                  <v-col cols="6" class="pl-2">
                    <h3>{{ $lang.header.newValues }}</h3>
                    <v-row v-if="permissionType === 'ROLE'" wrap no-gutters class="py-2">
                      <v-col cols="12" class="pb-2">
                        <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                          <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                          <user-roles-select
                            :role="editRolesIds"
                            :options="allRoles"
                            data-cy="roles-edit"
                            :required="false"
                            @changeRoles="editRolesIds = $event"
                          ></user-roles-select>
                          <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                          <user-roles-select
                            :role="useRolesIds"
                            :options="useRolePool"
                            data-cy="roles-use"
                            :required="false"
                            @changeRoles="useRolesIds = $event"
                          ></user-roles-select>
                          <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                          <user-roles-select
                            :role="viewRolesIds"
                            :options="viewRolePool"
                            data-cy="roles-view"
                            :required="false"
                            @changeRoles="viewRolesIds = $event"
                          ></user-roles-select>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>

              <v-row no-gutters justify="end">
                <v-btn text class="mr-2" @click="e1 = 1">
                  {{ $lang.actions.back }}
                </v-btn>

                <v-btn
                  color="primary"
                  :disabled="!resourcesToBeChanged.length"
                  @click="yesNoShow = true"
                >
                  {{ $lang.actions.run }}
                </v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card
                class="mb-6 pa-2"
                color="surface"
                min-height="366px"
              >
                <v-row
                  v-if="loading"
                  wrap
                  no-gutters
                  class="py-2"
                  justify="center"
                >
                  <v-progress-circular
                    :rotate="-90"
                    :size="150"
                    :width="12"
                    :value="progressPosition"
                    color="primary"
                  >
                    <h4>{{ progressPosition }}%</h4>
                  </v-progress-circular>
                </v-row>
                <v-row v-else wrap no-gutters class="py-2">
                  <v-col v-if="errorsFromRun.length" cols="6" class="col-class-left pr-2">
                    <template v-for="(item, i) in errorsFromRun">
                      <p :key="i">{{ item }}</p>
                    </template>
                  </v-col>
                  <v-col v-if="successFromRun.length" cols="6" class="pl-2">
                    <template v-for="(item, n) in successFromRun">
                      <p :key="n">{{ item }}</p>
                    </template>
                  </v-col>
                </v-row>
              </v-card>

              <v-row no-gutters justify="end">
                <v-btn v-if="errorsFromRun.length" text class="mr-2" @click="e1 = 2">
                  {{ $lang.actions.back }}
                </v-btn>
                <v-btn text class="mr-2" @click="resetForm()">
                  {{ $lang.actions.reset }}
                </v-btn>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <v-dialog v-if="yesNoShow" v-model="yesNoShow" max-width="30%">
      <yes-no :title="$lang.actions.start" :action-text="$lang.labels.thisWillOverwrite" @submit="startRunProcess" @closeDialog="yesNoShow = false"></yes-no>
    </v-dialog>
  </div>
</template>

<script>
import {
  getRolesUsingGET as getRoles,
  updateTranslationRolesUsingPUT as updateTranslation,
  updateModuleRolesUsingPUT as updateModule,
  updateProcessRolesUsingPUT as updateProcess,
  updateTriggerRestRolesUsingPUT as updateRest,
  updatePluginRolesUsingPUT as updatePlugin,
  updateTextTemplateRolesUsingPUT as updateTemplate,
  updateTriggerCronRolesUsingPUT as updateCron,
  updateValidationRuleRolesUsingPUT as updateValidation,
  updateTriggerMessagingRolesUsingPUT as updateMessaging,
  updateProcessCredentialRolesUsingPUT as updateProcessCredentialRoles,
  updateLibraryRolesUsingPUT as updateLibrary
} from '@/utils/api'
import ValuesComponent from '@/pages/mass-change/Values.vue'
import UserRolesSelect from '@/components/ui/UserRolesSelect.vue'
import YesNo from '@/components/ui/modals/YesNo.vue'

export default {
  components: { YesNo, UserRolesSelect, ValuesComponent },
  data() {
    return {
      yesNoShow: false,
      e1: 1,
      err: '',
      success: '',
      loading: false,
      resourcesTypes: ['GLOBAL_TRANSLATION', 'MODULE', 'PLUGIN', 'PROCESS', 'PROCESS_CREDENTIAL', 'TEXT_TEMPLATE', 'TRIGGER_CRON', 'TRIGGER_REST', 'TRIGGER_MESSAGING', 'VALIDATION_RULE', 'LIBRARY'],
      selectedResources: [],
      resourcesTypesLinks: {
        GLOBAL_TRANSLATION: 'templates/translations/edit',
        MODULE: 'modules/local/edit',
        PROCESS: 'processes/edit',
        PROCESS_CREDENTIAL: 'credentials/edit',
        TEXT_TEMPLATE: 'templates/edit',
        TRIGGER_CRON: 'triggers/cron/edit',
        TRIGGER_REST: 'triggers/rest/edit',
        VALIDATION_RULE: 'validations/edit',
        PLUGIN: 'plugins/edit',
        TRIGGER_MESSAGING: 'triggers/messaging/edit',
        LIBRARY: 'libraries/edit'
      },
      propertyTypes: ['ROLE'],
      permissionType: 'ROLE',
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      resourcesToBeChanged: [],
      errorsFromRun: [],
      successFromRun: [],
      finishedResourcesRun: 0
    }
  },
  computed: {
    progressPosition() {
      return (this.finishedResourcesRun / this.resourcesToBeChanged.length * 100).toFixed(0)
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    resourcesFormatted() {
      return this.resourcesTypes.map((item) => {
        return { text: this.$lang.status[item], value: item }
      }).sort((a, b) => a.text.localeCompare(b.text))
    },
    typesFormatted() {
      return this.propertyTypes.map((item) => {
        return { text: this.$lang.status[item], value: item }
      })
    }
  },
  created() {
    this.init()
    if (this.$route.params && this.$route.params.message) {
      this.success = this.$route.params.message
      setTimeout(() => this.success = '', 5000)
    }
  },
  methods: {
    resetForm() {
      this.e1 = 1
      this.err = ''
      this.success = ''
      this.loading = false
      this.selectedResources = []
      this.permissionType = 'ROLE'
      this.editRolesIds = []
      this.useRolesIds = []
      this.viewRolesIds = []
      this.resourcesToBeChanged = []
      this.errorsFromRun = []
      this.successFromRun = []
      this.finishedResourcesRun = 0
    },
    async updateRolesForResource(resource) {

      const data = {}

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      data.roles = tempRoles

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        switch (resource.type) {
        case 'GLOBAL_TRANSLATION':
          updateTranslation({ id: resource.id, resourceRolesRequest: data })
            .then(() => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch(() => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'MODULE':
          updateModule({ id: resource.id, resourceRolesRequest: data })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'PROCESS':
          updateProcess({
            id: resource.id,
            resourceRolesRequest: data
          })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'PROCESS_CREDENTIAL':
          updateProcessCredentialRoles({
            id: resource.id,
            resourceRolesRequest: data
          })
            .then((res) => {
              resolve(true)
            })
            // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TEXT_TEMPLATE':
          updateTemplate({ id: resource.id, resourceRolesRequest: data })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TRIGGER_CRON':
          updateCron({
            id: resource.id,
            resourceRolesRequest: data
          })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TRIGGER_REST':
          updateRest({ id: resource.id, resourceRolesRequest: data })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'VALIDATION_RULE':
          updateValidation({ id: resource.id, resourceRolesRequest: data })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'PLUGIN':
          updatePlugin({ id: resource.id, resourceRolesRequest: data })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'TRIGGER_MESSAGING':
          updateMessaging({ id: resource.id, resourceRolesRequest: data })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        case 'LIBRARY':
          updateLibrary({ id: resource.id, resourceRolesRequest: data })
            .then((res) => {
              resolve(true)
            })
          // eslint-disable-next-line handle-callback-err
            .catch((err) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(false)
            })
          break
        default:
        }
      })
    },
    startRunProcess() {
      this.yesNoShow = false
      this.e1 = 3
      this.loading = true
      this.err = ''
      this.success = ''
      this.finishedResourcesRun = 0

      const result = this.resourcesToBeChanged.map(async (x) => {

        let resourceUpdate = false

        switch (this.permissionType) {
        case 'ROLE':
          resourceUpdate = await this.updateRolesForResource(x)

          break
        default:
        }

        if (resourceUpdate) {
          this.successFromRun.push(`Successfully updated ${this.$lang.status[x.type]}: ${x.id}!`)
          this.finishedResourcesRun++

          return true
        } else {
          this.errorsFromRun.push(`Error while updating ${this.$lang.status[x.type]}: ${x.id}!`)
          this.finishedResourcesRun++

          return false
        }
      })

      Promise.all(result)
        .then((res) => {
          this.loading = false
        })
        // eslint-disable-next-line handle-callback-err
        .catch((err) => {
          this.loading = false
        })
    },
    init() {
      return new Promise((resolve) => {
        getRoles()
          .then((res) => {
            this.allRoles = res.data.data.items
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    setResources(type, data) {
      const tempByType = this.resourcesToBeChanged.filter((x) => x.type !== type)

      const formattedData = data.map((x) => {
        return { type, id: x.id ? x.id : x }
      })

      this.resourcesToBeChanged = [...tempByType, ...formattedData]
    },
    iconHandler(type) {
      switch (type) {
      case 'GLOBAL_TRANSLATION':
        return 'mdi-text'
      case 'MODULE':
        return 'mdi-view-module-outline'
      case 'PLUGIN':
        return 'mdi-puzzle-outline'
      case 'PROCESS':
        return 'mdi-console-network'
      case 'PROCESS_CREDENTIAL':
        return 'mdi-lock'
      case 'SETTING':
        return 'mdi-cog'
      case 'TEXT_TEMPLATE':
        return 'mdi-text-box-outline'
      case 'TRIGGER_CRON':
        return 'mdi-briefcase-clock-outline'
      case 'TRIGGER_MESSAGING':
        return 'mdi-message-text-outline'
      case 'TRIGGER_REST':
        return 'mdi-web'
      case 'VALIDATION_RULE':
        return 'mdi-magnify-scan'
      case 'USER':
        return 'mdi-account'
      case 'LIBRARY':
        return 'mdi-library-outline'
      default:
        return 'mdi-home'
      }
    }
  }
}
</script>
<style lang="scss">
.col-class-left {
  border-right: 1px solid var(--v-primary-base);
}
</style>
